.image_container {
  aspect-ratio: 3/4;
  flex: 22%;
  background-color: lightgray;
  position: relative;
  border-radius: 6px;
  transition: 0.1s all ease-in-out;
}

.image_container:hover {
  transform: scale(1.02);
  z-index: 4;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
}

.control_container {
  position: absolute;
  top: -10px;
  /* left: -5%; */
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 108%;
}

.control_icon_button {
  width: 28px;
  height: 28px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d1273f;
  color: white;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
}

.control_icon_button:hover {
  transform: scale(1.1);
}

.control_manage_picture_container {
  display: flex;
  gap: 5px;
}

.main_table {
  position: absolute;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  color: white;
  text-align: center;
  padding: 5px 0px;
  font-weight: 500;
}

.playButton {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
}
