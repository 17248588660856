.headerMiddleSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--box-shadow-light);
  padding: 15px 80px;
  background-color: white;
}

.headerNavBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerNavBar_Icons {
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-right: 50px;
}

.headerLogo {
  display: flex;
  margin-right: 50px;
}

.AddProfile {
  width: 160px;
  height: 56px;
  border-radius: 5px;
  border: 1px solid #d1273f;
  cursor: pointer;
  background-color: #d1273f;
  text-align: center;
  color: white;
  align-items: center;
  display: inline-block;
}

.SearchIcon {
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
}

.FormActive {
  display: flex;
  flex-grow: 0.5;
  border: 1px solid red;
  border-radius: 5px;
  margin-right: 50px;
}

.heaverButton {
  display: flex;
}

.Icontext {
  display: flex;
}

.mobile_items {
  display: none;
}

.balance {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 16px;
}

@media screen and (max-width: 356px) {
  .balance {
    display: none !important;
  }
}

@media all and (max-width: 1200px) {
  .Icontext {
    display: none;
  }
}

@media all and (max-width: 1000px) {
  .headerMiddleSectionContainer {
    display: none;
  }

  .mobile_items {
    display: inline;
  }
}

.typographyLocation {
  display: flex;
  align-items: center;
}

.itemContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}

.accountMenuItem {
  display: flex;
  gap: 5px;
  padding: 15px 80px;
  width: 100%;
  justify-content: center;
}

.menuItem:hover {
  background-color: lightgray;
}

.accountMenuItem:hover {
  background-color: transparent;
  color: #d1273f;
}

.drawerRoot {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  gap: 30px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
