.step_container {
  max-width: 400px !important;
  width: 100%;
  height: fit-content;
  max-height: 90vh;
  overflow-y: auto;
}

@media all and (max-width: 500px) {
  .step_container {
    max-width: 90vw !important;
  }
}

.form_container {
  padding: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-direction: column;
}
