.inputImageContainer {
  outline: 2px dotted rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
  height: 100%;
}

.mainText {
  position: relative;
  background: #d1273f;
  text-align: center;
  bottom: -70%;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  width: fit-content;
}

.image {
  border-radius: 6px;
  aspect-ratio: 3/4;
  width: 100%;
  height: 100%;
  background-size: cover;
  flex: 22%;
  cursor: pointer;
}

.image:hover {
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.15);
}

.step_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 600px;
  gap: 15px;
  overflow-x: hidden;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.deleteButton {
  background-color: white;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -30px;
  left: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 1;
}

.deleteButton:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.videoContainer {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border: 2px dotted rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section_header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  white-space: nowrap;

  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
}

.section_line {
  width: 100%;
  height: 1px;
}

.heading {
  margin-bottom: 15px;
  margin-top: 25px;
}

.audio_container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.media_state_container {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(22%, 1fr));
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 12px;
}

@media screen and (max-width: 764px) {
  .media_state_container {
    grid-template-columns: repeat(2, minmax(22%, 1fr));
  }
}
