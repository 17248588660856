.fullscreen_container {
  position: absolute;
  margin-top: -38px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.container_fill {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
