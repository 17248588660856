.container {
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 12px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 350px;
  width: 350px;

  @media screen and (max-width: 356px) {
    width: unset;
  }

  .header {
    display: flex;
    justify-content: space-between;
    .notif_header {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.message {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
