.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 48px;
  // padding: 20px;
  width: 100%;

  .header {
    display: flex;
    justify-content: center;
    padding: 20px;

    .header_inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1200px;
      gap: 25px;

      @media screen and (max-width: 764px) {
        justify-content: center;
      }

      .right_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;

        .button_container {
          display: flex;
          flex-wrap: wrap;
          @media screen and (max-width: 765px) {
            justify-content: center;
          }
          gap: 24px;
        }

        .text {
          max-width: 800px;
          @media screen and (max-width: 765px) {
            text-align: center;
          }
        }

        .heading_text {
          @media screen and (max-width: 356px) {
            font-size: x-large;
            padding: 10px;
          }
        }

        .description_text {
          @media screen and (max-width: 356px) {
            font-size: large;
          }
        }
      }

      .image_container {
        position: relative;
        aspect-ratio: 3/4;
        min-width: 350px;
        border-radius: 6px;
        box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        @media screen and (max-width: 450px) {
          min-width: unset;
          max-width: 80vw;
          width: 100%;
        }

        .girl_of_month_title {
          position: absolute;
          top: 0;
          z-index: 10;
          width: 100%;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 6px 6px 0 0;
          padding: 15px 0px;
          backdrop-filter: blur(4px);
        }

        .name_container {
          position: absolute;
          bottom: 0;
          padding: 15px 15px;
        }
      }
    }
  }
  .popular {
    width: 100%;
    display: flex;
    justify-content: center;
    .inner {
      width: 100%;
      padding: 20px;
      max-width: 1200px;

      .popular_inner_container {
        display: flex;
        @media screen and (max-width: 764px) {
          flex-wrap: wrap;
        }
        gap: 25px;

        .popular_girls_container {
          display: grid;
          flex: 60%;
          margin-top: 15px;
          grid-gap: 15px;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

          .text_cont {
            grid-column: span 2;
            display: flex;
            flex-direction: column;
            padding-top: 2rem;
            gap: 5px;

            @media screen and (max-width: 764px) {
              display: none;
            }
          }
        }

        .popular_right_container {
          flex: 40%;
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 15px;

          @media screen and (max-width: 356px) {
            text-align: center;
          }
        }
      }
    }
  }

  .user {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .user_inner {
      padding: 20px;
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 24px;
      }
    }
  }

  .recommended {
    width: 100%;
    display: flex;
    justify-content: center;
    // padding-bottom: 48px;
    .inner {
      width: 100%;
      padding: 20px;
      max-width: 1200px;
      .popular_inner_container {
        display: flex;
        gap: 25px;

        .popular_girls_container {
          display: grid;
          flex: 60%;
          margin-top: 15px;
          grid-gap: 15px;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
        .popular_right_container {
          flex: 40%;
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }
    }
  }
}

.girl_container {
  width: 100%;
  aspect-ratio: 3/4;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;

  .image {
    border-radius: 6px;
    transition: 0.2s all ease-in-out;

    &:hover {
      border-radius: 6px;

      transform: scale(1.05);
    }
  }

  .girl_card_bottom_container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 6px 6px;
    backdrop-filter: blur(4px);

    .girl_name_container {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;

      .price_container {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}

.link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: var(--primary) !important;
  }
}
