.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #e5e3f0;
  // padding-left: 24px;
  // padding-right: 24px;

  .footer_container {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 24px;
    // padding: 48px 0px 24px 48px;
    max-width: 1200px;
    padding-left: 24px;
    padding-right: 24px;

    .footer_top_container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 48px;
      width: 100%;
      margin-bottom: 24px;

      .icon_link {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .link {
        &:hover {
          text-decoration: underline;
        }
      }

      .logo {
        margin-bottom: 24px;
      }

      .link_container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .links_cont {
          display: flex;
          flex-direction: column;
          // column-gap: 24px;
          gap: 8px;
        }
      }

      .account_header {
        text-align: right;
        margin-bottom: 8px;
      }

      .social_container {
        display: flex;
        align-items: center;
        gap: 8px;

        .social_text {
          transition: 0.1s all ease-in-out;
          cursor: pointer;

          &:hover {
            color: var(--primary) !important;
          }
        }

        .socialIconBase {
          cursor: pointer;
          aspect-ratio: 1;
          background-color: #2d203a;
          padding: 4px;
          width: 24px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          transition: 0.1s all ease-in-out;
          &:hover {
            background-color: var(--primary);
            color: white;
          }
        }
      }

      .social_containers {
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
        gap: 8px;

        .social_button_base {
          aspect-ratio: 1;
          width: 60px;
          padding: 4px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: lightgray;
          transition: 0.2s all ease-in-out;
          &:hover {
            background-color: var(--primary);
            color: white !important;
          }
        }
      }
    }
  }

  .bottom_text_container {
    padding-top: 24px;
    // text-align: center;
  }
}

.image_container {
  position: relative;
  height: 35px;
  margin-left: -15px;
}

// .footer_container {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;

//   background-color: #e5e3f0;
//   .container {
//     width: 100%;
//     max-width: 90vw;
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     padding: 24px;
//     flex: 100%;

//     .second_container {
//       display: grid;
//       grid-template-columns: auto auto;
//       // flex-direction: column;
//       // gap: 4px;
//       text-align: left;
//     }
//     .third_container {
//       display: flex;
//       flex-direction: column;
//       gap: 12px;
//     }
//     .links_container {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 48px;
//     }
//     .section {
//       display: flex;
//       flex-direction: column;
//       max-width: 200px;
//     }
//     .sectionHeader {
//       margin-bottom: 5px;
//     }
//   }
// }

// .media_container {
//   display: flex;
//   gap: 4px;

//   .media_button {
//     cursor: pointer;
//     border-radius: 12%/45%;
//     background-color: var(--primary);
//     padding: 8px 12px;
//     display: flex;
//     gap: 8px;
//     align-items: center;
//     transition: 0.1s all ease-in-out;
//     &:hover {
//       transform: scale(1.05);
//     }
//   }
// }
