.item_container {
  display: flex;
}

.root {
  padding: 0 !important;
  margin: 0 !important;
}

.root_logo {
  padding: 25px !important;
  display: flex;
  width: 100% !important;
  justify-content: center;
}

.root_item {
  text-align: center;
  padding: 32px 64px !important;
  display: flex;
  justify-content: center;
}

.root_item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
