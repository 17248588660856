.buttons_container {
  display: flex;
  gap: 16px;
  .type_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 15px;
    transition: 0.1s all ease-in-out;
    aspect-ratio: 1;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    cursor: pointer;
  }
}
