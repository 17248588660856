.icon {
  margin-right: 5px;
  opacity: 0.5;
}

.heading {
  width: 100%;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.bottom {
  display: flex;
  gap: 5px;
}

.bottom_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .checkbox_container {
    display: flex;
    align-items: center;
  }
}

.center {
  text-align: center;
}

.back_row {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.subheading {
  text-align: left;
  width: 100%;
}

.headingRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
