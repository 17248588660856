.contactRowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.contactsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.input_root {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 6px;
}
