.show_more_container {
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;

  .link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.popover_root {
  padding: 0 !important;
  overflow: visible !important;
}
