.root {
  border: 0;
  border-radius: 7px;
  background-color: #edeef0;
  outline: 0;
  width: 100%;
}

.girlSearchContainer {
  gap: 15px;
  display: flex;
  transition: 0.1s all ease-in-out;
  cursor: pointer;
  width: 100%;
}

.girlSearchContainer:hover {
  background-color: lightgray;
}

.girlSearchImage {
  aspect-ratio: 3/4;
  max-width: 80px;
  width: 100%;
  border-radius: 6px;
  margin: 15px;
}
