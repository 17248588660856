.icon {
  margin-right: 5px;
  opacity: 0.5;
}

.heading {
  width: 100%;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.bottom_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .checkbox_container {
    display: flex;
    align-items: center;
  }
}
