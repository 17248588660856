.cardContainer {
  -webkit-font-smoothing: antialiased;
  flex: 10%;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  aspect-ratio: 3/5;
  cursor: pointer;
}
