.nav > a {
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  display: block;
}

.nav > a:hover {
  text-decoration: underline;
  z-index: 0;
}

.header_container {
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 14px;

  /* padding: 14px 60px; */
  align-items: center;
  position: fixed;
  position: -webkit-fixed;
  top: 0;
  z-index: 20;
  min-height: 48px;
  max-height: 48px;
  gap: 48px;
  width: 100%;
}

@media screen and (max-width: 765px) {
  .header_container {
    gap: 12px !important;
  }
}

.searchFieldContainer {
  position: absolute;
  width: 100%;
  top: -50%;
  bottom: 50%;
  z-index: 1;
}

.logo {
  position: relative;
  width: 100%;
  max-width: 256px;
  min-height: 50px;
  cursor: pointer;
}

.burger_menu_button {
  display: none !important;
  margin-left: 20px;
}

@media all and (max-width: 1300px) {
  .image_nav {
    display: none;
  }
}

.image_nav_small {
  display: none;
}

@media all and (max-width: 1300px) and (min-width: 1000px) {
  .image_nav_small {
    display: block;
    padding-left: 20px;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  gap: 30px;
  color: white;
  position: relative;
  margin-left: 20px;
}

@media all and (max-width: 1000px) {
  .nav {
    display: none;
  }
  .burger_menu_button {
    display: inline-block !important;
  }
}

.newButton:hover {
  background-color: rgba(255, 25, 25, 1);
}

.button {
}

@media screen and (max-width: 390px) {
  .button {
    display: none !important;
  }
}

.login_link:hover {
  text-decoration: underline;
}

.image_nav {
  flex: 100%;
  width: 100%;
  cursor: pointer;
}

.image_container {
  position: relative;
  height: 35px;
}

.preview_container {
  flex: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
}

.selected_link {
  display: block;
  color: var(--primary);
  text-shadow: 0 0 0.7px var(--primary), 0 0 0.7px var(--primary);
  /* font-weight: 700; */
  /* text-decoration: underline; */
  /* transform: scale(0.95); */

  /* text-decoration-color: var(--primary); */
  /* text-decoration-thickness: 0.1rem; */
  /* text-underline-offset: 5px; */
}
