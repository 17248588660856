.content {
  padding: 16px;
  display: flex;
  gap: 34px;
  max-width: 300px;
  text-align: center;

  flex-direction: column;
  .button_row {
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: space-between;
  }

  .icon_row {
    display: flex;
    justify-content: center;
  }
}
