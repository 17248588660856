.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  text-align: center;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
  }
}

.unselected {
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.selected {
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid var(--primary);
}
